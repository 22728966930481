import { Expose, Type } from "class-transformer";
import { AnswerQuestion, Forum, Comment, Post, News } from ".";
export class Notification {
  @Expose() id?: string;
  @Expose() name?: string;
  @Expose() content?: string;
  @Expose() title?: string;
  @Expose({ name: "sender_id" }) senderID?: string;
  @Expose({ name: "sender_ids" }) senderIDs?: any[] = [];
  @Expose({ name: "count_other_sender" }) countOtherSender?: number;
  @Expose({ name: "receiver_id" }) receiverID?: string;
  @Expose({ name: "objectable_id" }) objectableID?: string;
  @Expose({ name: 'message' }) message: any = {};
  @Expose({ name: "notification_type" }) notificationType?: string;
  @Expose({ name: "confirm_job_application_status" })
  confirmJobApplicationStatus?: string;
  @Expose({ name: "deadline_confirm" }) deadlineConfirm?: string;
  @Expose({ name: "detail" }) detail?: string;
  @Expose({ name: "created_at" }) createdAt?: string;
  @Expose({ name: "conversation_id" }) conversationID?: string;
  @Expose({ name: "candidate_support_conversation_id" })
  candidateSupportConversationID: string;
  @Expose({ name: "company_support_conversation_id" })
  companySupportConversationID: string;
  @Expose({ name: "all_conversation_id" }) allConversationID: string;
  @Expose({ name: "read" }) read?: boolean;
  @Expose({ name: "message_id" }) messageID?: string;
  @Expose({ name: 'files' }) files: File[] = [];
  @Expose({ name: 'files_send' }) files_send: File[] = [];
  @Expose({ name: 'reads' }) reads: any[] = [];
  @Expose({ name: 'parent_id' }) parentID?: any;
  @Expose({ name: 'parent' }) parent?: any;
  @Expose({ name: 'sending' }) sending: string = '';
  @Expose({ name: 'translation' }) translation: any = {};
  @Expose({ name: 'message_search' }) messageSearch: boolean = false;
  @Type(() => Request)
  @Expose({ name: 'request' }) request?: Request;
  updated: boolean = false;
  deleted: boolean = false;
  isEndOfOther?:boolean;
  isEndOfDay?:boolean;
  @Expose({ name: 'can_call' }) canCall?: boolean;
  @Expose({ name: 'deadline' }) deadline?: string;
  @Type(() => AnswerQuestion)
  @Expose({ name: 'answer' }) answer?: AnswerQuestion;
  @Type(() => Forum)
  @Expose({ name: 'question' }) question?: Forum;
  @Type(() => EventCancel)
  @Expose({ name: 'event' }) event?: EventCancel;
  @Type(() => RatingScales)
  @Expose({ name: "rating_scales" }) ratingScales?: RatingScales[];
  @Type(() => Comment)
  @Expose({ name: 'comment' }) comment?: Comment;
  @Type(() => Post)
  @Expose({ name: 'blog' }) post?: Post;
  @Type(() => News)
  @Expose({ name: 'blog' }) news?: News;

  constructor() {
    this.allConversationID = "";
    this.candidateSupportConversationID = "";
    this.companySupportConversationID = "";
    this.senderIDs = [];
  }
}

export class EventCancel {
  @Expose({name: "user_id"}) userId?: string;
  @Expose({name: "content"}) content?: string;
  @Expose({name: "event"}) event?: string;
}
class RatingScales {
  @Expose({ name: "survey_form_item_id" }) id?: string;
  @Expose({ name: "rating_scale" }) ratingScale?: number;
}
