var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "notfound" } }, [
    _vm.error.message == "ERROR_ERR_NETWORK_DISCONNECTED" || _vm.$nuxt.isOffline
      ? _c(
          "div",
          { staticClass: "container pt-4" },
          [_c("error-network", { on: { refeshPage: _vm.refeshPage } })],
          1
        )
      : _c(
          "div",
          { staticClass: "notfound" },
          [
            _c("div", { staticClass: "notfound-404" }, [
              _c("h1", [
                _vm._v(
                  _vm._s(
                    _vm.error.statusCode !== 606 ? _vm.error.statusCode : ""
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.error.statusCode === 404 || _vm.error.statusCode === 405
              ? _c("h2", [_vm._v(_vm._s(_vm.$t("error.ERR_SER0102")))])
              : _vm._e(),
            _vm._v(" "),
            _vm.error.statusCode === 500
              ? _c("h2", [
                  _vm._v(_vm._s(_vm.$t("error.page_error_title_other"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            [404, 500, 405].includes(_vm.error.statusCode)
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("error.page_error_title_content")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            [404, 500, 405].includes(_vm.error.statusCode)
              ? _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                  _vm._v(_vm._s(_vm.$t("error.button_content_error"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error.statusCode === 403
              ? _c("div", [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.$t("error.page_error_title_403"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("error.page_error_content_403"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("a", { on: { click: _vm.goToHome } }, [
                    _vm._v(_vm._s(_vm.$t("error.button_content_error"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error.statusCode === 606
              ? _c("div", [
                  _c("h2", [_vm._v(_vm._s(_vm.$t(_vm.error.message)))]),
                  _vm._v(" "),
                  _vm.$i18n.locale === "vi"
                    ? _c(
                        "div",
                        [
                          _c(
                            "nuxt-link",
                            { attrs: { to: _vm.switchLocalePath("ja") } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("error.button_content_switch"))
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "nuxt-link",
                            { attrs: { to: _vm.switchLocalePath("vi") } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("error.button_content_switch"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }