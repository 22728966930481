import { Expose } from "class-transformer";
export class Comment {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "created_by"}) createdBy?: string;
  @Expose({name: "updated_by"}) updatedBy?: string;
  @Expose({name: "created_at"}) createdAt?: string;
  @Expose({name: "updated_at"}) updatedAt?: number;
  @Expose({name: "deleted_at"}) deletedAt?: string;
  @Expose({name: "reply_cnt"}) repliesCnt: number = 0;
  @Expose() name?: string;
  @Expose({name: "content"}) content: string = '';
  @Expose({name: "avatar_url"}) avatarUrl?: string;
  @Expose({name: "like_cnt"}) likeCnt?: number;
  @Expose({name: "is_liked"}) isLiked?: boolean;
  @Expose({name: "dislike_cnt"}) dislikeCnt?: number;
  @Expose({name: "is_disliked"}) isDisliked?: boolean;
  @Expose() replies: Comment[] = [];
  @Expose({name: "parent_id"}) parentId?: string;
  @Expose() showEdit: boolean = false;
  @Expose() showAction: boolean = false;
  @Expose() oldContent: string = '';
}