import { Expose, Type } from "class-transformer";
export class Banner {
  @Expose() id?: string;
  @Expose() createdBy?: string;
  @Expose() updatedBy?: string;
  @Expose() createdAt?: string;
  @Expose() updatedAt?: string;
  @Expose() deletedAt?: string;
  @Expose() bannerUrl?: string;
  @Expose() bannerPosition?: string;
}