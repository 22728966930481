import { Expose } from "class-transformer";
import { Profile } from '~/modals';
export class Forum {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "content"}) content?: string;
  @Expose({name: "title"}) title?: string;
  @Expose({name: "created_at"}) createdAt?: number;
  @Expose({name: "author_id"}) authorId?: string;
  author?: Profile;
  @Expose({name: "liked"}) liked?: boolean;
  @Expose({name: "is_liked"}) isLiked?: boolean;
  @Expose({name: "image_urls"}) images?: string[];
  @Expose({name: "tag_id"}) tagId?: string;
  @Expose({name: "hashtag_ids"}) hashTags?: string[];
  @Expose({name: 'comment_cnt'}) totalComment?: number;
  @Expose({name: 'like_cnt'}) totalLike?: number;
  @Expose({name: 'hashtag_ids'}) hashtags?: string[];
  @Expose({name: 'is_bookmarked'}) isBookmark?: boolean;
  @Expose({name: 'disliked'}) disliked?: boolean;
  @Expose({name: "is_disliked"}) isDisliked?: boolean;
  readMore?: boolean;
  isSocial?: boolean;
  isMoreOwner?: boolean;
  isEditData?: boolean;
  isViewComment?: boolean;
  isFilter?: boolean;
  isButtonDelete: boolean = true;
  isButtonEdit: boolean = true;
  warningLikeQuestion?: boolean;
  warningLikeAnswer?: boolean;
  warningLikeReplyOfAnswer?: boolean;
}
export class QuestionForum {
  id?: string;
  content?: string;
  @Expose({name: "image_urls"}) images?: string[] = [];
  @Expose({name: "tag_id"}) tagId?: string;
  @Expose({name: "hashtag_ids"}) hashTags: string[] = [];
  title?: string;
  isAddData?: boolean;
  isEditData?: boolean;
}
export class AnswerQuestion {
  id?: string;
  content?: string;
  @Expose({ name: "created_at" }) createdAt?: number;
  @Expose({ name: "created_by" }) createdBy?: string;
  @Expose({ name: "reply_cnt" }) totalComment?: number;
  @Expose({ name: "like_cnt" }) totalLike?: number;
  @Expose({ name: "liked" }) liked?: boolean;
  @Expose({ name: "parent_id" }) parentId?: string;
  @Expose({ name: "question_id" }) questionId?: string;
  @Expose({ name: "image_urls" }) images?: string;
  @Expose({ name: "pinned" }) pinned?: boolean;
  @Expose({ name: "disliked" }) disliked?: boolean;
  pinnerId?: string;
  pinner?: Profile;
  isReply?: boolean;
  isMoreOwner?: boolean;
  isUpdate?: boolean;
  isAddNew?: boolean;
  readMore?: boolean;
}