<template>
  <div id="notfound">
    <div class="container pt-4" 
      v-if="error.message == 'ERROR_ERR_NETWORK_DISCONNECTED' || $nuxt.isOffline">
      <error-network @refeshPage="refeshPage" />
    </div>
    <div class="notfound" v-else>
        <div class="notfound-404">
            <h1>{{error.statusCode !== 606 ? error.statusCode : ''}}</h1>
        </div>
        <h2 v-if="error.statusCode === 404 || error.statusCode === 405">{{ $t('error.ERR_SER0102') }}</h2>
        <h2 v-if="error.statusCode === 500">{{ $t('error.page_error_title_other') }}</h2>
        <p v-if="[404, 500, 405].includes(error.statusCode)">
          {{ $t('error.page_error_title_content') }}
        </p>
        <nuxt-link v-if="[404, 500, 405].includes(error.statusCode)" :to="localePath('/')" >{{ $t('error.button_content_error') }}</nuxt-link>
        <div v-if="error.statusCode === 403">
          <h2>{{ $t('error.page_error_title_403') }}</h2>
          <p>{{ $t('error.page_error_content_403') }}</p>
          <br>
          <br>
          <br>
          <a @click="goToHome">{{ $t('error.button_content_error') }}</a>
        </div>
        <div v-if="error.statusCode === 606">
          <h2>{{ $t(error.message) }}</h2>
          <div v-if="$i18n.locale === 'vi'">
            <nuxt-link :to="switchLocalePath('ja')">{{ $t('error.button_content_switch') }}</nuxt-link>
          </div>
          <div v-else>
            <nuxt-link :to="switchLocalePath('vi')">{{ $t('error.button_content_switch') }}</nuxt-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ErrorNetwork from '~/components/ErrorNetwork.vue';

export default {
  scrollToTop: true,
  props: ['error'],
  layout: 'empty',
  components: {
    ErrorNetwork
  },
 head () {
   const __title = this.error.statusCode === 404 ? this.error.statusCode + this.$t('error.page_error_header_404').toString() : 
                    this.$t('common.error').toString();
    return {
      title: __title,
      link: [{rel: 'alternate icon', type: 'image/x-icon',href: this.getIconByMode}]
    }
  },
  created() {
    if (this.error.message == 'ERROR_ERR_NETWORK_DISCONNECTED' || this.$nuxt.isOffline) {
      this.$nuxt.setLayout('default');
    } else {
      this.$nuxt.setLayout('empty');
    }
  },
  methods: {
    goToHome(){
      // this.clearStorage(this.$auth);
      // this.$auth.logout();
      this.$router.push(
        this.localePath({
          name: `/`,
        })
      );
    },
    refeshPage() {
      try {
        if (this.$nuxt.isOffline) {
          return;
        }
        window.location.reload();
      } catch (error) {
        //TODO
      }
    },
    clearStorage(auth){
      const tokenKey = '_token.' + auth.strategy.name;
      const refreshTokenKey = '_refresh_token.' + auth.strategy.name;
      const tokenExpiresAt = '_token_expires_at.' + auth.strategy.name;
      const refreshTokenExpiresAt = '_refresh_token_expires_at.' + auth.strategy.name;
      const clientId = '_client_id.' + auth.strategy.name;
      const tokenGoogle = '_token.google';
      auth.$storage.removeUniversal(tokenKey);
      auth.$storage.removeUniversal(refreshTokenKey);
      auth.$storage.removeUniversal(tokenExpiresAt);
      auth.$storage.removeUniversal(refreshTokenExpiresAt);
      auth.$storage.removeUniversal(clientId);
      auth.$storage.removeUniversal(tokenGoogle);
    }
  },
  computed: {
    getIconByMode(){
      let _url = '';
      if (process.client) {
        if (window.matchMedia) {
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            _url = '/icon.png'
          } else {
            _url = '/icon.png'
          }
        }
      }
      return _url;
    }
  }
}
</script>
<style lang="scss" scoped>
#notfound {
  position: relative;
  height: calc(100vh - #{$height-nav-bar});
  overflow: hidden;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  // font-family: 'Maven Pro', sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound h2 {
  // font-family: 'Maven Pro', sans-serif;
  font-size: 39px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  // font-family: 'Maven Pro', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  // font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

.footer {
  display: none;
}
@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }
}
</style>