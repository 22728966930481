
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {
    TopNavBar: () => import("@/components/TopNavBar.vue")
  }
})
export default class LayoutAuth extends Vue {
}
