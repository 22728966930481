var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "client-only",
    [
      _c("v-focus-trap", [
        _c(
          "div",
          {
            class: [{ "wrapper-mobile": _vm.$device.isMobile }],
            attrs: { id: "app" },
          },
          [
            _c("keep-alive", [_c("top-nav-bar")], 1),
            _vm._v(" "),
            _c("nuxt", { staticClass: "body" }),
            _vm._v(" "),
            _c(
              "client-only",
              [
                _c("footer-base", { staticClass: "footer" }),
                _vm._v(" "),
                _c("scroll-top-component"),
                _vm._v(" "),
                _c("FacebookMessenger"),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }