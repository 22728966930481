import { Expose } from "class-transformer";
import { PostCategory } from "./post_category";

export class Post {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "title"}) title?: string;
  @Expose({name: "summary"}) summary?: string;
  @Expose({name: "content"}) content?: string;
  @Expose() views?: number;
  @Expose() status?: string;
  @Expose({name: "count_views"}) countViews?: number = 0;
  @Expose({name: "thumbnail_url"}) thumbnailUrl?: string;
  @Expose({name: "category"}) category?: PostCategory;
  @Expose({name: "created_at"}) publishedAt?: string;
  @Expose({name: "like_cnt"}) likeCnt?: number;
  @Expose({name: "created_by"}) createdBy?: string;
  @Expose({name: "hashtags"}) hashtags?: string[];
  @Expose({name: "hashtag_ids"}) hashtag_ids: string[] = [];
  @Expose({name: "category_id"}) categoryId?: string[];
  @Expose({name: "language"}) language?: string[];
  @Expose({name: "is_liked"}) isLiked?: boolean;
  @Expose({name: "is_disliked"}) isDisliked?: boolean;
  @Expose({name: "dislike_cnt"}) dislikeCnt?: number;
  @Expose({name: "favorite"}) bookmark?: boolean;
  @Expose() isLikedOld?: boolean;
  @Expose() isDislikedOld?: boolean;
  @Expose() likeCntOld?: number;
  @Expose() author: any;

  constructor(){
    this.id = '';
    this.title= '';
    this.summary = '';
    this.content= '';
    this.views= 0;
    this.status= '';
    this.thumbnailUrl= '';
    this.category = new PostCategory();
    this.publishedAt= '';
    this.likeCnt = 0;
    this.dislikeCnt = 0;
    this.countViews =0;
    this.createdBy = '';
  }
}