var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("nuxt"),
      _vm._v(" "),
      _vm.$store.state.loading.loading
        ? _c("div", { staticClass: "loading-animation" }, [
            _c("img", {
              staticStyle: { width: "60px" },
              attrs: { src: require("assets/icons/icon-spinner.svg"), alt: "" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("notifications", {
            staticClass: "custom-style__notifications",
            attrs: {
              group: "custom-notifications-network",
              position: "bottom left",
              duration: 500000,
              "animation-type": "velocity",
              max: 1,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c("toast-network", { on: { "close-toast": props.close } }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }