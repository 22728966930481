
import { Component, Vue } from 'nuxt-property-decorator'
import TopNavBar from '@/components/TopNavBar.vue';
import FacebookMessenger from "~/components/fb/FacebookMessenger.vue";

@Component({
  components: {
    TopNavBar,
  // TopNavBar: () => import("@/components/TopNavBar.vue"),
  FooterBase: () => import("@/components/FooterBaseLanding.vue"),
  ScrollTopComponent: () => import("~/components/ScrollToTopComponent.vue"),
  FacebookMessenger
  }
})
export default class LayoutLandingPage extends Vue {
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      // link: [{rel: 'alternate icon', type: 'image/x-icon',href: this.icon.png}],
      meta: [
        { hid: 'og:description', "property": "og:description", content: this.$t('landing_page.community_connection').toString().replace("</br>", "") },
        { hid: 'twitter:description', name: "twitter:description", content: this.$t('landing_page.community_connection').toString().replace("</br>", "") },
        { hid: 'og:image', "property": "og:image", content: this.getBanner },
        { hid: 'twitter:image', name: "twitter:image", content: this.getBanner }
      ],
    }
  }
  mounted() {
    this.$moment.locale(this.$i18n.locale);
    window.addEventListener("storage", async (e) => {
      try {
        if(e.key === 'auth._token.local' && !this.$isIE) {
          // if(!e.oldValue) {
          //  return; }
          if(e.newValue == 'false') {
            window.location.reload();
          } else { 
            window.location.reload();
          }
          return;
        }
      } catch (error) {
      }
    });
  }
  // get icon.png(){
  //   let _url = '';
  //   if (process.client) {
  //     if (window.matchMedia) {
  //       if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //         _url = '/icon.png'
  //       } else {
  //         _url = '/icon.png'
  //       }
  //     }
  //   }
  //   return _url;
  // }
  get getBanner() {
    if (this.$i18n.locale === 'vi') {
      return '/banner_facebook_vn.jpg';
    }
    else if (this.$i18n.locale === 'ja') {
      return '/banner_facebook_jp.jpg';
    }
    return '/banner_facebook_global.jpg';
  }
}
