import "reflect-metadata";
import "es6-shim";
import { Expose, Type } from "class-transformer";
import {Language} from './'

export class Country {
  @Expose() id?: string;
  @Expose() name?: string;
  @Expose() code?: string;
  @Type(() => Language) language?: Language;
}
