// import "reflect-metadata";
// import "es6-shim";
import { Expose } from "class-transformer";

export class Profile {
  @Expose({ name: "user_id" }) userID?: string;
  @Expose({ name: "property_id" }) propertyID?: string;
  @Expose() name?: string;
  @Expose() role?: string;
  @Expose() avatar?: string;
  @Expose() department?: string;
  @Expose({ name: "user_system" }) userSystem?: boolean;
  @Expose() authorPost: any;
}
