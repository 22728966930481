export default function ({ app, redirect, $auth }) {
  // if ($auth.loggedIn && $auth.user.property_id) {
  //   return redirect(app.localePath({
  //     name: 'candidates-slug',
  //     params: {slug: $formatSlugUrl($auth.user.name, $fromUUID($auth.user.property_id))}
  //   }));
  // }
  if(!$auth.loggedIn) {
    return redirect(app.localePath({
      name: '/'
    }));
  }
}
