
export default function ({app, redirect, route, $auth, $formatSlugUrl, $fromUUID}) {
  if (!$auth.loggedIn && (route.name.includes('my-question') || route.name.includes('interested-tags') )) {
    return redirect(app.localePath('/'));
  }
  if ($auth.loggedIn && $auth.user.role !== 'CANDIDATE') {
    const _name = $auth.user.role === 'COMPANY' ? 'companies-slug' : 'translators-slug'
    return redirect(app.localePath({
      name: _name,
      params: {slug: $formatSlugUrl($auth.user.name, $fromUUID($auth.user.property_id))}
    }));
  }
}