import { Expose } from "class-transformer";

export class News {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "title"}) title?: string;
  @Expose({name: "summary"}) summary?: string;
  @Expose({name: "content"}) content?: string;
  @Expose({name: "thumbnail_url"}) thumbnailUrl?: string;
  @Expose({name: "published_at"}) publishedAt?: string;
  @Expose({name: "bookmark"}) bookmark: boolean = false;
  @Expose({name: "hot"}) hot?: boolean;
  @Expose({name: "published"}) published?: boolean;
  @Expose({name: "category_id"}) categoryId: string = '';
  @Expose({name: "is_liked"}) isLiked?: boolean;
  @Expose({name: "is_disliked"}) isDisliked?: boolean;
  @Expose({name: "dislike_cnt"}) dislikeCnt?: number;
  @Expose({name: "like_cnt"}) likeCnt?: number;
  @Expose({name: "count_views"}) countViews: number = 0;
  @Expose() isLikedOld?: boolean;
  @Expose() isDislikedOld?: boolean;
  @Expose() likeCntOld?: number;
  // constructor(){
  //   this.id = '';
  //   this.title= '';
  //   this.summary = '';
  //   this.content= '';
  //   this.thumbnailUrl= '';
  //   this.publishedAt= '';
  //   this.bookmark = false;
  //   this.hot = false;
  //   this.published = false;
  // }
}