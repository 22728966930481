
export default function ({app, redirect, $auth}) {
  // if (!$auth.loggedIn && process.client) {
  if (!$auth.loggedIn) {
    return;
  }
  if ($auth.loggedIn && $auth.user.role !== 'CANDIDATE') {
    return;
  }
  if ($auth.user.support_language.code === 'ja') {
    return redirect(app.localePath('/forums'));
  }
  return redirect(app.localePath('/posts'));
}