import { Expose, Type } from "class-transformer";
export class Report {
  @Expose({name: "id"}) id?: string;
  @Type(() => MultipleLanguageName)
  @Expose({name: "names"}) multipleNames?: MultipleLanguageName[];
  @Expose({name: "name"}) name?: MultipleLanguageName;
  @Expose({name: "general_report_item_id"}) generalReportItemId?: String;
}

class MultipleLanguageName {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "language_code"}) languageCode?: string;
  @Expose({name: "name"}) name?: string;
}

export class AddReport {
  reportId?: string;
  @Expose({name: "report_item_id"}) reportItemId?: string;
  @Expose({name: "objectable_id"}) objectableId?: string;
  @Expose({name: "objectable_type"}) objectableType?:string;
  @Expose({name: "content"}) content?: string;
}

export class UserReport {
  id?: string;
  @Expose({name: "user_id"}) userId?: string;
  @Expose({name: "objectable_id"}) objectableId?: string;
  @Expose({name: "objectable_type"}) objectableType?:string;
  @Expose({name: "name"}) name?: string;
  @Expose({name: "report_item_id"}) reportItemId?: string;
  @Expose({name: "content"}) content?: string;
  @Expose({name: "created_at"}) createdAt?: number;
  done?: boolean;
  available?: boolean;
}