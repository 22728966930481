
export default function ({app, redirect, route, $auth, params, $formatSlugUrl, $fromUUID}) {
	const locale = params.lang;
	if($auth.loggedIn && $auth.user.role === 'CANDIDATE'){
		if(!$auth.user?.support_language){
			if(!route.path.includes("/countries")){
				return redirect(app.localePath({
                    name: "countries"
                  }, locale));
			}
		}else{
			if(route.path.includes("/countries")){
				return redirect(app.localePath({
                    name: "/"
                  }, locale));
			}
		}
	}else{
		if(route.path.includes("/countries")){
			return redirect(app.localePath({
				name: "/"
			  }, locale));
		}
	}
}
