var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll-lock",
          rawName: "v-scroll-lock",
          value: _vm.showChangePasswordModal || _vm.showChangeLanguageModal,
          expression: "showChangePasswordModal || showChangeLanguageModal ",
        },
      ],
      attrs: { id: "app-container" },
    },
    [
      _c(
        "div",
        {
          staticClass: "menu-top-nav-bar",
          class: [
            {
              "d-none":
                (_vm.$route.path.includes("/about") ||
                  _vm.$route.path.includes("/contact")) &&
                !_vm.$device.isMobileOrTablet,
            },
          ],
        },
        [
          _c(
            "keep-alive",
            [
              _c("top-nav-bar", {
                on: {
                  openLogoutModal: _vm.openLogoutModal,
                  openChangePasswordModal: _vm.openChangePasswordModal,
                  openChangeLanguageModal: _vm.openChangeLanguageModal,
                  "error-notification": _vm.errorNotification,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "body",
          class: [
            { "pt-0": _vm.$device.isMobile },
            {
              "pt-0":
                _vm.$auth.loggedIn &&
                _vm.$auth.user.role === "CANDIDATE" &&
                _vm.homePageCandidate &&
                !_vm.$device.isMobile,
            },
            {
              "pt-0":
                (_vm.$route.path.includes("/about") ||
                  _vm.$route.path.includes("/contact")) &&
                !_vm.$device.isMobileOrTablet,
            },
          ],
        },
        [
          _c("keep-alive", [_c("nuxt")], 1),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "loading-animation",
            },
            [
              _c("img", {
                staticStyle: { width: "60px" },
                attrs: {
                  src: require("assets/icons/icon-spinner.svg"),
                  alt: "",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("facebook-messenger"),
          _vm._v(" "),
          _vm.$route.path !== _vm.localePath("/forums") &&
          _vm.$route.path !== _vm.localePath("/forums/my-question")
            ? _c("footer-base", { staticClass: "footer" })
            : _vm._e(),
          _vm._v(" "),
          _c("scroll-top-component"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSuccessAlertModal
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showSuccessAlertModal,
                  expression: "showSuccessAlertModal",
                },
              ],
            },
            [
              _c("dialog-success", {
                attrs: {
                  title: _vm.dialogSuccessTitle,
                  content: _vm.dialogSuccessContent,
                  button: _vm.$t("authentication.sign_in"),
                },
                on: { "click-yes": _vm.onConfirmYes },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showConfirmAlertModal
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showConfirmAlertModal,
                  expression: "showConfirmAlertModal",
                },
              ],
            },
            [
              _c("dialog-confirm", {
                attrs: {
                  title: _vm.$t("authentication.sign_out"),
                  content: _vm.$t("authentication.comfirm_logout"),
                },
                on: {
                  "click-yes": function ($event) {
                    return _vm.onLogout("")
                  },
                  "click-no": function ($event) {
                    _vm.showConfirmAlertModal = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRevokeLogoutModal
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.showRevokeLogoutModal,
                  expression: "showRevokeLogoutModal",
                },
              ],
            },
            [
              _c("revoke-logout-modal", {
                attrs: { title: _vm.$t("authentication.sign_out") },
                on: {
                  "click-yes": _vm.onConfirmRevokeLogout,
                  closeRevokeLogoutModal: function ($event) {
                    _vm.showRevokeLogoutModal = false
                  },
                },
                model: {
                  value: _vm.isRevokeAll,
                  callback: function ($$v) {
                    _vm.isRevokeAll = $$v
                  },
                  expression: "isRevokeAll",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisible
        ? _c(
            "full-modal",
            [
              _c("dialog-confirm", {
                attrs: {
                  title: _vm.$t("common.notification"),
                  content: _vm.$t("error.expired_all_token"),
                  isButtonYes: true,
                  isButtonNo: false,
                  isButtonClose: false,
                },
                on: {
                  "click-yes": function ($event) {
                    _vm.clearData()
                    _vm.$store.dispatch(
                      "account-deleted-modal/changeShowModal",
                      false
                    )
                  },
                  "click-no": function ($event) {
                    _vm.clearData()
                    _vm.$store.dispatch(
                      "account-deleted-modal/changeShowModal",
                      false
                    )
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isErrorNotificactions
        ? _c(
            "full-modal",
            [
              _c("dialog-error", {
                attrs: {
                  title: _vm.$t("common.notification"),
                  content: _vm.errorNotificationContent,
                  button: _vm.$t("common.ok"),
                },
                on: {
                  "click-yes": function ($event) {
                    _vm.isErrorNotificactions = !_vm.isErrorNotificactions
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showChangePasswordModal
        ? _c("change-password-modal", {
            on: {
              closeChangePasswordModal: function ($event) {
                _vm.showChangePasswordModal = false
              },
              changePasswordSuccess: _vm.changePasswordSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showChangeLanguageModal
        ? _c("change-language-modal", {
            on: {
              closeChangeLanguageModal: function ($event) {
                _vm.showChangeLanguageModal = false
              },
              changeLanguageSuccess: _vm.changeLanguageSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("notifications", {
            staticClass: "custom-style__notifications",
            attrs: {
              group: "custom-notifications",
              position: "bottom left",
              duration: 5000,
              "animation-type": "velocity",
              max: 2,
              ignoreDuplicates: true,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c("toast-item-view", {
                      attrs: {
                        id: "TOAST_ITEM_" + props.item.data.id,
                        notification: props.item.data,
                      },
                      on: { "close-toast": props.close },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("notifications", {
            staticClass: "custom-style__notifications",
            attrs: {
              group: "custom-notifications-network",
              position: "bottom left",
              duration: 500000,
              "animation-type": "velocity",
              max: 1,
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c("toast-network", { on: { "close-toast": props.close } }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("notifications", {
            attrs: {
              group: "copy_link",
              position: "bottom left",
              duration: 3000,
              max: 1,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.state.report.showReport
        ? _c(
            "full-modal",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.$store.state.report.showReport && !_vm.$isiOS,
                  expression: "$store.state.report.showReport && !$isiOS",
                },
              ],
            },
            [
              _vm.$auth.loggedIn
                ? _c("add-report")
                : _c("dialog-confirm", {
                    attrs: {
                      title: _vm.$t("common.notification"),
                      content: _vm.$t("report.warning_not_logged_in"),
                    },
                    on: {
                      "click-yes": () => {
                        _vm.gotoLogin()
                        _vm.$store.dispatch("report/hideReport")
                      },
                      "click-no": function ($event) {
                        return _vm.$store.dispatch("report/hideReport")
                      },
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [_c("portal-target", { attrs: { name: "modal-outlet" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }