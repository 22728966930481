import "reflect-metadata";
import "es6-shim";
import { Expose,  } from "class-transformer";

export class Owner {
  @Expose() id?: string;
  @Expose() name?: string;
  @Expose() role?: string;
  @Expose() avatar?: string;
  @Expose({name: "language_code"}) languageCode?: string;
  @Expose() address?: string;
}
