
import { Component, Vue, Watch } from "nuxt-property-decorator";
import { namespace } from "vuex-class";
import {
  Notification,
  Candidate,
} from "~/modals";
import TopNavBar from '@/components/TopNavBar.vue';
import FacebookMessenger from "~/components/fb/FacebookMessenger.vue";

const NotificationStore = namespace("notification");
const ProfileStore = namespace('profile');
const LoadingStore = namespace("loading");
const userInfoStore = namespace("userInfo");
const candidateStore = namespace("candidate");
const MessageStore = namespace("message");
const AccountDeletedModalStore = namespace("account-deleted-modal");

@Component({
  middleware: ['i18n-guard'],
  components: {
    FullModal: () => import('@/components/FullModal.vue'),
    DialogSuccess: () => import('@/components/DialogSuccess.vue'),
    DialogConfirm: () => import('@/components/DialogConfirm.vue'),
    DialogError: () => import('@/components/DialogError.vue'),
    Modal: () => import('~/argon-components/Modal.vue'),
    ChangePasswordModal: () => import('~/components/ChangePasswordModal.vue'),
    FooterBase: () => import('@/components/FooterBaseNew.vue'),
    ChangeLanguageModal: () => import('~/components/ChangeLanguageModal.vue'),
    ToastItemView: () => import('~/components/ToastItemView.vue'),
    ScrollTopComponent: () => import('~/components/ScrollToTopComponent.vue'),
    RevokeLogoutModal: () => import('~/components/RevokeLogoutModal.vue'),
    ToastNetwork: () => import('@/components/ToastNetwork.vue'),
    AddReport: () => import('@/components/report/AddReport.vue'),
    FacebookMessenger,
    TopNavBar
},
  data: () => ({
    showSuccessAlertModal: false,
    showConfirmAlertModal: false,
    dialogSuccessTitle: "",
    dialogSuccessContent: "",
    showRegisterModal: false,
    showRevokeLogoutModal: false,
    isRevokeAll: "",
    onConfirmYes: () => {},
    onConfirmRevokeLogout: () => {}
  })
})
export default class DefaultLayout extends Vue {
  showDefaultLayout: boolean = true;
  showSuccessAlertModal: boolean = false;
  showConfirmAlertModal: boolean = false;
  showChangePasswordModal: boolean = false;
  showChangeLanguageModal: boolean = false;
  showRegisterModal: boolean = false;
  showRevokeLogoutModal: boolean = false;
  isErrorNotificactions: boolean = false;
  errorNotificationContent: string = "";

  @NotificationStore.State newNotification;
  @NotificationStore.Action resetUnreadNotifications;
  @NotificationStore.Action unsubscribe;
  @MessageStore.Action resetUnreadMessages;
  @LoadingStore.State loading;
  @ProfileStore.Action addProfileIDToQueue;
  @ProfileStore.Action fetchNewProfiles;
  @ProfileStore.State profiles;
  @ProfileStore.State queueIDs;
  @ProfileStore.Action clearToProfile;
  @userInfoStore.Action setUserInfo;
  @candidateStore.Action setCurrentCandidate;
  @AccountDeletedModalStore.State isVisible;
  @AccountDeletedModalStore.Action changeShowModal;

  dialogSuccessTitle?: string;
  dialogSuccessContent?: string;
  isRevokeAll?: string;
  currentYear : number = (new Date()).getFullYear();
  notificationTypesNotNotify: string[] = [
    "",
    "READ_MESSAGE",
    "READ_NOTIFICATION",
    "COMMENT_JOB",
    "BLOG",
    "ADMIN",
    "DELETE_FILE_MESSAGE_JOB_APPLICATION",
    "DELETE_CONTENT_MESSAGE_JOB_APPLICATION",
    "EDIT_MESSAGE_JOB_APPLICATION",
    "DELETE_FILE_MESSAGE_REQUEST",
    "DELETE_CONTENT_MESSAGE_REQUEST",
    "EDIT_MESSAGE_REQUEST",
    "NEWS"
  ];
  STATUS_INTERVIEW_POLL = ['VOTE_POLL'];
  notificationStartVideoCall?: Notification;
  notShowNotificationRequestContent= ['WAITING_FOR_HELPER', 
    'HELPER_JOINED', 
    'OWNER_ACCEPTED_APPLY', 
    'HELPER_FINISHED', 
    'OWNER_REFUSED_FINISHED', 
    'FINISHED', 
    'OWNER_CANCEL', 
    'OWNER_ACCEPTED_FINISHED',
    'NEW_REQUEST_TRANSLATION_JOB',
    'OWNER_REJECT_APPLY',
    'CANCELED'];
  notShowNotificationApplicationContent = [
    'NEW_REQUEST_SUPPORT_JOB_APPLICATION',
    'COMPANY_ACCEPTED_APPLY',
    'HELPER_JOINED',
    'COMPANY_ACCEPTED_CANDIDATE',
    'COMPANY_REFUSED_CANDIDATE',
    'CANDIDATE_CANCEL',
    'COMPANY_CANCEL',
    'FINISHED',
    'CANCELED',
    'CANDIDATE_ANSWER_ENTRY_SHEET'];
  notificationTimer = '';
  onConfirmYes?: () => void;
  onConfirmRevokeLogout?: () => void;

  head(){
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      // link: [{rel: 'alternate icon', type: 'image/x-icon', href: this.getIconByMode},
            // this.$nuxtI18nSeo().link.filter(
            //   (item) => (item.rel = 'alternate' && item.hreflang)
            // )
          // ],
      meta: [
        { hid: 'description', name: "description", content: this.$t('landing_page.community_connection').toString().replace("</br>", "") },
        { hid: 'og:description', "property": "og:description", content: this.$t('landing_page.community_connection').toString().replace("</br>", "") },
        { hid: 'twitter:description', name: "twitter:description", content: this.$t('landing_page.community_connection').toString().replace("</br>", "") },
        { hid: 'og:image', "property": "og:image", content: this.getBanner },
        { hid: 'twitter:image', name: "twitter:image", content: this.getBanner }
      ],
    }
  }
  get homePageCandidate() {
    return this.$route.name?.includes('index') || 
    (this.$route.name?.includes('recruitment') &&  this.$nuxt.isOnline);
  }
  get getIconByMode(){
    let _url = '';
    if (process.client) {
      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          _url = '/icon.png'
        } else {
          _url = '/icon.png'
        }
      }
    }
    return _url;
  }

  @Watch("$i18n.locale")
  onChangeLocale(newValue: string, oldValue: string) {
    if (this.$moment.locales().indexOf(newValue) > -1) {
      this.$moment.locale(newValue);
    }
    if (!this.$auth.loggedIn) {
      this.$store.dispatch('profile/clearToProfile');
    }
  }
  @Watch("$auth.loggedIn")
  onChangedLoggedIn(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      if (newValue === true && this.$auth.user && this.$auth.user.support_language
      && this.$auth.user.role == 'CANDIDATE') {
        this.$store.dispatch('category/clearCategory');
        this.startSession();
        this.configOneSignal();
      } else {
        this.unsubscribe();
      }
    }
  }
  @Watch('$nuxt.isOffline')
  onChangeNetwork(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.$notify({
        group: "custom-notifications-network",
        data: {}
      });
    } else {
      window.location.reload();
    }
  }
  @Watch('$i18n.locale')
  onChangeLang(newVal: string, oldVal: string){
    if(this.$auth.loggedIn){
      if(newVal !== this.$auth.user.setting_language.code){
        this.$i18n.locale = this.$auth.user.setting_language.code;
      }
    }
  }
  @Watch("newNotification")
  async onReceiveNotification(newVal: Notification, oldVal: Notification) {
    
    try {
      if(newVal && JSON.stringify(newVal) != JSON.stringify(oldVal) 
        && !this.notificationTypesNotNotify.includes(newVal.notificationType || '')){
        if (newVal.notificationType === 'SYSTEM' 
          && newVal.content === 'REVOKE_OTHER_LOGIN'
          && (newVal as any).receiverID === this.$auth.user.id) {
          // this.onLogout('/login'); 
          // this.$store.dispatch('account-deleted-modal/changeShowModal', true);
          return;
        }
        // if (!(this.queueIDs as any)?.includes((newVal as any)!.senderIDs[0])) {
        //   this.addProfileIDToQueue((newVal as any)!.senderIDs[0]);
        // }
        (newVal as any)!.senderIDs.forEach(memberID => {
          if (!(this.queueIDs as any)?.includes(memberID)) {
            this.addProfileIDToQueue(memberID);
          }
        })
        const newNotifiToast = {...newVal};
        let title = '';
        if(newVal.id === oldVal?.id) {
          title = (newVal.id?.toString() || '') + newVal.senderIDs?.toString();
        } else {
          title = (newVal.content?.toString() || '') + newVal.notificationType?.toString() + newVal.objectableID?.toString + newVal.senderIDs?.toString();
        }

        if(newVal.id){
          const idOldToastElm = 'TOAST_ITEM_' + newVal.id;
          const oldToastElm = document.getElementById(idOldToastElm);
          if(oldToastElm){
            oldToastElm.remove();
          }
        }
        
        this.$notify({
          group: "custom-notifications",
          data: newNotifiToast,
          title: title //check ignore duplicate
        });
      }  
    } catch (e) {
    }
  }
  @Watch('queueIDs', {immediate: true}) 
  getNewProfile(current, old) {
    if (!Array.isArray(current) || !current.length) {
      return;
    }
    this.fetchNewProfiles();
  }
  created(){
    const backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
      //Guard back to landing page when loggedIn
      if (this.$auth.loggedIn && to.path === this.localePath('/') && from) {
        next({
          name: from.name || "",
          params: from.params,
          query: from.query,
          hash: from.hash
        });
      } else if(((this.$auth.loggedIn && this.$auth.user?.role == 'COMPANY' 
        && from.name?.includes('companies-slug')) || from.name?.includes('translators-slug')) 
          && to.name?.includes('index') && this.$auth.loggedIn) {
        next({
          name: from.name,
          params: from.params,
          query: from.query,
          hash: from.hash
        });
      } else {
        next();  
      }
      this.clickButtonBackInBrowserCallBack();
    });
    this.$once('hook:destroyed', () => {
      backButtonRouteGuard();
    });
  }
  async mounted() {
    if(process.env.BASE_URL_API != 'https://api.goemon.vi-jp-te.info'){
      console.clear();
      console.log('%c' + this.$t('error.warning_stop'), 'color: red; font-size: 50px; font-weight: bold;');
      console.log('%c' + this.$t('error.warning_inspect'), 'color: black; font-size: 20px; font-weight: 500;')
    }
    await this.checkDuplicate();

    this.$moment.locale(this.$i18n.locale);
    window.addEventListener("storage", async (e) => {
      try {
        if(e.key === 'auth._token.local' && !this.$isIE) {
          window.location.reload();
          return;
        }
      } catch (error) {
      }
    });
    if (this.$auth.loggedIn && this.$auth.user.role !== 'ADMIN' ) {
      this.unsubscribe();  
      this.subscribeNotification();
      this.configOneSignal();
    }

    let navigationType = (window.performance.getEntriesByType("navigation")[0])['type'];
    //back_forward value is duplicate tab
    if(navigationType == 'back_forward'){
      // window.location.reload();
      // alert('Tab is duplicated...');
    }
  }
  async startSession() {
    try {
      // this.setUserInfo(plainToClass(UserInfo, this.$auth.user))
      // await Promise.all([
      //   this.subscribeNotification(),
      //   // this.$store.dispatch('prefetchUser')
      // ]);
      await this.subscribeNotification()
    } catch (e) {
      // TODO : handle Error
    }
  }
  async checkDuplicate(){
      try{
        let navigationType = (window.performance.getEntriesByType("navigation")[0])['type'];
        if (navigationType == 'back_forward') {
          this.$nextTick(async () => {
            await this.$nuxt.refresh();
          })
        }
      }catch(e){
      }
  }
  configOneSignal() {
    // this.$OneSignal.push(() => {
    //   this.$OneSignal.isPushNotificationsEnabled(async (isEnabled) => {
    //     if (isEnabled) {
    //       const externalUserId = await this.$OneSignal.getExternalUserId();
    //       if(!externalUserId || externalUserId !== this.$auth.user.id) {
    //         if (externalUserId) {
    //           await this.$OneSignal.removeExternalUserId();
    //         }
    //         await this.$OneSignal.setExternalUserId(this.$auth.user.id);
    //         await this.$OneSignal.setSubscription(true);
    //         await this.$OneSignal.sendTag("location", this.$auth.user.support_language.code);
    //       }
    //     } else {
    //     }
    //   });
    // });
  }
  clickButtonBackInBrowserCallBack() {
    if (this.showChangeLanguageModal) {
      this.showChangeLanguageModal = false;
    }
  }
  async subscribeNotification() {
    await this.$store.dispatch("notification/subscribe");
  }
  async onLogout(route) {
    if(!route) route = '/';
    await this.unsubscribe();
    this.$auth.logout().then(() => {
      // this.clearStorage(this.$auth);
      // this.unsubscribe();
      this.$store.dispatch('category/clearCategory');
      this.$store.dispatch('categoryHashtag/clearCategoryHashtag');
      this.clearToProfile();
      this.$store.dispatch('userInfo/clearUserInfo');
      this.setCurrentCandidate(new Candidate());
      this.resetUnreadNotifications();
      this.resetUnreadMessages();
      this.$router.push((this as any).localePath(route));
    });
    this.showConfirmAlertModal = false;
  }

  openLogoutModal() {
    this.showConfirmAlertModal = true;
  }
  openChangePasswordModal(){
    this.showChangePasswordModal = true;
  }
  openChangeLanguageModal(){
    this.showChangeLanguageModal = true;
  }
  changePasswordSuccess(){
    this.showChangePasswordModal = false, 
    this.showRevokeLogoutModal = true;
    this.onConfirmRevokeLogout = () => {
      if(this.isRevokeAll === 'YesRevokeAll') {
        this.onRevokeAllLogout();
        this.showRevokeLogoutModal = false;
      }
      else {
        this.showRevokeLogoutModal = false;
      }
    }
  }
  async onRevokeAllLogout() {
    try {
      await this.$axios.$post(`api/v1/oauth/revoke-all`, {
          email: this.$auth.user.email,
      })
      .then(res => {
        this.onLogout('/login'); 
      })
    } catch(err) {
    }
  }
  changeLanguageSuccess(){
    this.showChangeLanguageModal = false;
  }
  gotoLogin() {
    this.redirectLoginToUrl();
  }
  get getBanner() {
    if (this.$i18n.locale === 'vi') {
      return '/banner_facebook_vn.jpg';
    }
    else if (this.$i18n.locale === 'ja') {
      return '/banner_facebook_jp.jpg';
    }
    return '/banner_facebook_global.jpg';
  }
  clearData() {
    this.$store.dispatch('category/clearCategory');
    this.$store.dispatch('categoryHashtag/clearCategoryHashtag');
    this.clearToProfile();
    this.$store.dispatch('userInfo/clearUserInfo');
    this.setCurrentCandidate(new Candidate());
    this.resetUnreadNotifications();
    this.resetUnreadMessages();
    this.$router.push((this as any).localePath('/'));

    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }
  errorNotification(errorCode){
    errorCode = errorCode.replace('.', '_');
    this.isErrorNotificactions = true;
    this.errorNotificationContent = this.$t(`error.${errorCode}`).toString();
  }
}
