<template>
  <div id="app">
    <nuxt></nuxt>
    <div v-if="$store.state.loading.loading" class="loading-animation">
      <img src="~/assets/icons/icon-spinner.svg" alt=""  style="width: 60px">
    </div>
    <client-only>
      <notifications
        group="custom-notifications-network"
        position="bottom left"
        :duration="500000"
        animation-type="velocity"
        :max="1"
        class="custom-style__notifications"
      >
        <template slot="body" slot-scope="props">
          <toast-network @close-toast="props.close"/>
        </template>
      </notifications>
    </client-only>
  </div>
</template>
<script>
export default {
  name: 'empty',
  components: {
    ToastNetwork: () => import("@/components/ToastNetwork.vue")
  },
  head(){
    return {
      link: [{rel: 'alternate icon', type: 'image/x-icon',href: this.getIconByMode}]
    }
  },
  mounted() {
    this.$watch('$nuxt.isOffline', (newVal) => {
      if (newVal) {
        this.$notify({
          group: "custom-notifications-network",
          data: {}
        });
      }
    });
  },
  computed: {
    getIconByMode(){
      let _url = '';
      if (process.client) {
        if (window.matchMedia) {
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            _url = '/icon.png'
          } else {
            _url = '/icon.png'
          }
        }
      }
      return _url;
    }
  }
}
</script>
