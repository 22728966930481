
import { Vue, Watch } from "nuxt-property-decorator";
import { Mixin } from "vue-mixin-decorator";

@Mixin
export default class SetWidthDOM extends Vue {
  currentDOM?: Element | HTMLElement;
  currentClass = '.sync-width-popup';
  isDetectMobile = false;
  smallDevice?: MediaQueryList;

  mounted() {
    this.currentDOM = this.$el?.querySelector(this.className) as Element;
    this.setWidth();
    
    this.smallDevice = window.matchMedia("(max-width: 991.98px)");
    this.smallDevice.addEventListener('change',this.handleDeviceChange);
    // Initial check
    this.handleDeviceChange(this.smallDevice);
  }
  handleDeviceChange(e) {
    this.isDetectMobile = e.matches as boolean;
  }
  set className(value) {
    this.currentClass = value;
  }
  get className() {
    return this.currentClass;
  }
  setWidth() {
    this.$watch(() => this.$store.state.navbar.widthPopup, (newVal, oldVal) => {
      if (!newVal) {
        return;
      }
      this.currentDOM?.setAttribute('style', `width: ${newVal}px`);
    }, {immediate: true});
  }
  @Watch('$route.name')
  onChangeRoute(newVal, oldVal) {
    if (!newVal) {
      return;
    }
    this.currentDOM?.removeAttribute('style');
  }
  beforeDestroy() {
    // window.removeEventListener('resize', this.adjustWidth);
    this.currentDOM?.removeAttribute('style');
  }
}
