var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("keep-alive", [_c("top-nav-bar")], 1),
      _vm._v(" "),
      _c("nuxt", { staticClass: "body" }),
      _vm._v(" "),
      _vm.$store.state.loading.loading
        ? _c("div", { staticClass: "loading-animation" }, [
            _c("img", {
              staticStyle: { width: "60px" },
              attrs: { src: require("assets/icons/icon-spinner.svg"), alt: "" },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }