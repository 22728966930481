require('reflect-metadata')
import { Expose, Type } from "class-transformer";
import { Account } from "./account";
import EnglishLevel from "./englishLevel";
import {EnglishCertificate} from "./englishCertificate";
import {JapaneseLevel} from "./japaneseLevel"
export  class Candidate {
  // --------person-----------
  @Expose() id?: string;
  @Type(() => Account) account?: Account;
  @Expose({ name: "first_name" }) firstName?: string;
  @Expose({ name: "last_name" }) lastName?: string;
  @Expose({name: "birthday"}) birthday?: string;
  @Expose({name: "post_code"}) postCode?: string;
  @Expose() email?: string;
  @Expose({name: "user_system" }) userSystem?: boolean;
  @Expose({name: "country_id" }) countryId?: string | null;
  @Expose({name: "country" }) country?: string;
  @Expose({name: "city_id"}) cityId?: string;
  @Expose({name: "city"}) city?: string | null;
  @Expose() address?: string;
  @Expose() avatar?: string;
  @Expose() country_language_code?: string;
  // ------career---------
  @Expose({name: "education_id" }) educationId?: string | null;
  @Expose({name: "school_name"}) schoolName?: string | null;
  @Expose({name: "department_id" }) departmentId?: string | null;
  @Expose({ name: "graduation_schedule" }) graduationSchedule?: string | null;
  @Expose({name: "certificate_urls"}) certificateUrls?: any = [];
  @Expose({name: "japanese_level_listening"}) japaneseLevelListening?: string;
  @Expose({name: "japanese_level_speaking"}) japaneseLevelSpeaking?: string;
  @Expose({name: "japanese_level_writing"}) japaneseLevelWriting?: string;
  @Expose({name: "japanese_level_reading"}) japaneseLevelReading?: string;
  @Expose({name: "japanese_level_jlpt"}) japaneseLevelJLPT?: string;
  @Expose({name: "japanese_level"})
  @Type(() => JapaneseLevel) japaneseLevel?: JapaneseLevel;
  @Expose({ name: "english_level" })
  @Type(() => EnglishLevel) englishLevel?: EnglishLevel;
  @Expose({name: "other_language_level"}) otherLanguage?: String | OtherLanguageLevel;
  @Expose({name: "levelEnglish"}) levelEnglish?: string;
  @Expose({name: "certificates"}) certificates: EnglishCertificate[] = [];
  @Expose({name: "scoreEnglish"}) scoreEnglish?: string;
  // @Expose({name: "certificates"}) englishCertificate?: EnglishCertificate[];
  // --------expected--------
  @Expose({name: 'expected_general_industry_id'}) expectedIndustryId?:string;
  @Expose({name: 'expected_general_occupation_id'}) expectedOccupationId?:string;
  @Expose({name: 'expected_specific_industry_id'}) expectedIndustrySpecificId?:string;
  @Expose({name: 'expected_specific_occupation_id'}) expectedOccupationSpecificId?:string;
  @Expose({name: 'career_path_option'}) careerPathOption?:string;
  @Expose({name: 'career_path_other'}) careerPathOther?:string;
  @Expose({name: 'skills'}) skills?:string;
  @Expose({name: 'outline_of_your_research'}) outlineOfYourResearch?: string;
  @Expose({name: 'expected_salary'}) expectedSalary?: string;
  @Expose({name: 'currency_id'}) currencyId?:string;
  @Expose({ name: "is_match_original" }) isMatchOriginal?: boolean;

  public setOtherLanguage(otherLanguage: String | OtherLanguageLevel) {
    if (!this.otherLanguage) {
      this.otherLanguage = otherLanguage;
    }
  }
  public sortCertificates() {
    const indexOf = this.englishLevel?.certificates?.findIndex(_o => _o.certificate == "OTHER") ?? -1;
    const filterCertificates = [...(this.englishLevel?.certificates ?? [])];
    const arrOther = indexOf >= 0 ? filterCertificates.splice(indexOf, 1) : [];
    const sortCertificates = filterCertificates.sort((a,b) => a!.certificate!.toLowerCase().localeCompare(b!.certificate!.toLowerCase()));
    this.certificates = [...sortCertificates, ...arrOther];
  }
  setJapaneseLevel() {
    if(this.japaneseLevel){
      this.japaneseLevelListening = this.japaneseLevel.japaneseLevelListening
      this.japaneseLevelSpeaking = this.japaneseLevel.japaneseLevelSpeaking
      this.japaneseLevelWriting = this.japaneseLevel.japaneseLevelWriting
      this.japaneseLevelReading = this.japaneseLevel.japaneseLevelReading
      this.japaneseLevelJLPT = this.japaneseLevel.japaneseLevelJLPT
    }
  }
}
interface OtherLanguageLevel {
  detail: '';
}
