
import { Vue } from "nuxt-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import { namespace } from "vuex-class";
import { City, Common } from "~/modals";
import { plainToClass } from "class-transformer";

const CountryStore = namespace("country");
const CityStore = namespace("city");
const LanguageStore = namespace("language");
const currencyStore = namespace("currency");
const departmentStore = namespace("department");
const educationStore = namespace("education");
const LoadingStore = namespace("loading");
const BannerStore = namespace("banner");
const TagInterestStore = namespace("interest-tags");
const ProfileStore = namespace('profile');


@Mixin
export default class CommonMixins extends Vue {
  @CountryStore.State countries;
  @CityStore.State cities;
  @LanguageStore.State languages;
  @currencyStore.State currencies;
  @departmentStore.State departments;
  @educationStore.State educations;
  @BannerStore.State banners;
  @ProfileStore.State profiles;

  @BannerStore.Getter  getBanners;
  @TagInterestStore.Getter getTagInterestIDs;
  @ProfileStore.Getter getProfileByPropertyID;

  @LoadingStore.Action setLoading;
  @TagInterestStore.Action setTagInterest;
  @TagInterestStore.Action resetTagInterest;

  industry = {};
  city = {};
  district = {};
  getWidthScreen = 0;
  isDetectMobile = false;
  smallDevice?: MediaQueryList;

  get getCurrencies(){
    return this.currencies;
  }
  getCurrencyById(__id) {
    return this.findById(this.currencies, __id);
  }
  getCurrencyNameById(__id) {
    return this.findNameById(this.currencies, __id);
  }
  get getCountries() {
    return this.countries;
  }
  getCountryById(__id) {
    return this.findById(this.countries, __id);
  }
  getCountryNameById(__id) {
    return this.findNameById(this.countries, __id);
  }
  get getCity() {
    return this.cities;
  }
  getCityById(__id) {
    return this.findById(this.cities, __id);
  }
  getCityNameById(__id) {
    return this.findNameById(this.cleanSource(this.cities), __id);
  }
  getCityNameByIdAndLanguageCode(__id, lang) {
    return this.findNameByIdAndLanguageCode(this.cleanSource(this.cities), __id, lang);
  }
  getLanguage(__id) {
    return this.languages.find(o1 => o1.id === __id);
  }
  getLanguageByCode(_code) {
    return this.languages.find(o1 => o1.code === _code);
  }
  getLanguageNameById(__id) {
    return this.languages.find(o1 => o1.id === __id) ? 
            this.languages.find(o1 => o1.id === __id)['name'] : '';
  }
  getBanenrs(){
    return this.banners;
  }
   async getListBanner(){
    const rs =  await this.$axios.get(`api/v1/banners`);
    return rs;
  }
  async getListDistrictByCityId(__cityId){
    const rs =  await this.$axios.get(`api/v1/cities/${__cityId}/districts`);
    return rs;
  }
  async getListCityByCountryCode(__countryCode){
    const rs =  await this.$axios.get(`api/v1/countries/${__countryCode}/cities`);
    return rs;
  }
  findById(_arrayInput, __id){
    return _arrayInput
        .find(o1 => o1.id === __id);
  }
  goToDetailUser(nameUser, idUser){
    return (this as any).localePath({
      name: 'other-candidates-slug',
      params: { slug: this.$formatSlugUrl(nameUser, idUser)}
    })
  }

  findNameById(_arrayInput, __id) {
    const lang = this.$auth.user ? this.$auth.user.support_language.code : this.$i18n.locale;
    try {
      let tmpObject = _arrayInput.find(o1 => o1.id === __id);
      if (tmpObject && (tmpObject as any).names) {
        const tmpObj = (tmpObject as any).names.find(o2 => o2.language_code === lang);
        return plainToClass(Common, tmpObj);
      }
      return {
        name: ''
      };
    } catch (error) {
      return {
        name: ''
      };
    }
  }
  findNameByIdAndLanguageCode(_arrayInput, __id, languageCode) {
    let tmpObject = {}
    try {
      tmpObject = _arrayInput.find(o1 => o1.id === __id);
      if (Object.keys(tmpObject).length > 0 
          && ((tmpObject as any).names && (tmpObject as any).names.length > 0)) {
        const tmpObj = (tmpObject as any).names.find(o => (o as any).language_code === languageCode);
        return plainToClass(Common, tmpObj || {name: ''});
      }
      return {
        name: ''
      };
    } catch (error) {
      return {
        name: ''
      };
    }
  }
  filteredArray(_arrayInput){
    try {
      return _arrayInput.map(rs => {
        if (rs.names.length > 0) {
          rs.names = rs.names.filter(_o => _o.language_code === (this.$auth.loggedIn ? this.$auth.user.support_language.code : this.$i18n.locale));
        } else {
          rs.names.push({id: '', name: ''});
        }
        rs.name = rs.names[0].name;
        rs.language_code = rs.names[0].language_code;
        return rs;
      });
    } catch (error) {
      //Handle error
      return [];
    }
  }
  filteredArrayLanguageCode(_arrayInput, __languageCode){
    try {
      return _arrayInput.map(rs => {
        if (rs.names.length > 0) {
          rs.names = rs.names.filter(_o => _o.language_code === __languageCode);
        } else {
          rs.names.push({id: '', name: ''});
        }
        rs.name = rs.names[0].name;
        rs.language_code = rs.names[0].language_code;
        return rs;
      });
    } catch (error) {
      return [];
    }
  }
  findArray(_arrayInput){
    try {
      return _arrayInput.map(rs => {
        rs.names = rs.names.find(_o => _o.language_code === this.$auth.user.support_language.code);
        return rs;
      });
    } catch (error) {
      return [];
    }
  }
  cleanSource (source) {
    return JSON.parse(JSON.stringify(source));
  }
  convertHTMLToText(_strHtml){
    return _strHtml;
  }
  formatNumber(num, __unit) {
    if (!num && num < 0) {
      return '';
    }
    return `${(num + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${__unit}`;
  }
  formatStr(__str) {
    return __str.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase());
  }
  async validateTab(refName) {
    const isValid = await (this.$refs[refName] as any).validate();
    if (!isValid) {
      setTimeout(() => {
        const [first] = Object.entries((this.$refs[refName] as any).ctx.errors)
                      .map(([key, value]) => ({ key, value }))
                      .filter(error => (error["value"] as any).length);
        (this.$refs[refName] as any).refs[first["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        (this.$refs[refName] as any).refs[first["key"]].$el.focus();
      }, 100);
    }
    return isValid;
  }
  mounted() {
    this.smallDevice = window.matchMedia("(max-width: 991.98px)");
    this.smallDevice.addEventListener('change',this.handleDeviceChange);
    // Initial check
    this.handleDeviceChange(this.smallDevice);
  }
  handleDeviceChange(e) {
    this.getWidthScreen = window.innerWidth;
    this.isDetectMobile = e.matches as boolean;
  }
  // get getWidthScreen(){
  //   return process.client ? screen.width : 0;
  // }
  get getHeightScreen(){
    return process.client ? screen.height : 0;
  }
  beforeDestroy() {
    this.smallDevice?.removeEventListener('change', this.handleDeviceChange);
  }
  displayTime (value) {
    if (!value) {
      return '-';
    }

    let seconds = Math.abs(this.$moment(Number(value)).diff(this.$moment(),'seconds'));
    let interval = seconds / 32536000;
    if (interval > 1){
      return Math.floor(Number(interval)) + this.$t('job.year_ago').toString();
    }
    interval = seconds / 2592000
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('job.month_ago').toString();
    }
    interval = seconds / 86400
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('job.day_ago').toString();
    }
    interval = seconds / 3600
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('job.hour_ago').toString();
    }
    interval = seconds / 60
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('job.minute_ago').toString();
    }
    return this.$t('time.just_now_forums').toString();
  }
  
  displayTimeComment(value) {
    if (!value) {
      return '-';
    }

    let seconds = Math.abs(this.$moment(Number(value)).diff(this.$moment(),'seconds'));
    let interval = seconds / 32536000;
    if (interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.year_forums').toString();
    }
    interval = seconds / 2592000
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.month_forums').toString();
    }
  interval = seconds / 604800
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.week_forums').toString();
    }
    interval = seconds / 86400
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.day_forums').toString();
    }
    interval = seconds / 3600
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.hour_forums').toString();
    }
    interval = seconds / 60
    if(interval > 1){
      return Math.floor(Number(interval)) + this.$t('time.minute_forums').toString();
    }
    return this.$t('time.just_now_forums').toString();
  }
  formatDateTimeCommon(time) {
    let timeFormat = this.$moment(Number(time)).locale(this.$i18n.locale).format('LLLL');
    return timeFormat.charAt(0).toString().toUpperCase() + timeFormat.toString().slice(1);
  }
  displayCreateTime(value) {
    if (!value) {
      return '-';
    }
    const FORMAT_DAY_MONTH = {
      'en': 'MMMM D',
      'ja': 'MMMM D',
      'vi': 'D MMMM',
      'pt': 'MMMM D',
    }

    const FORMAT_DAY_MONTH_YEAR = {
      'en': 'MMMM D YYYY',
      'ja': 'YYYY年M月D日',
      'vi': 'D MMMM YYYY',
      'pt': 'MMMM D YYYY',
    }

    let seconds = Math.abs(this.$moment(Number(value)).diff(this.$moment(),'seconds'));

    let sameYear = this.$moment(value).isSame(this.$moment(),'year');
  
    let interval = seconds / 31536000;

    let mmtMidnight = this.$moment(Number(value)).endOf('day');

    let timeToMidnight = (Math.abs(this.$moment(Number(value)).diff(mmtMidnight,'seconds'))) / 86400;
  
    if (!sameYear) {
      return this.$moment(Number(value)).locale(this.$i18n.locale).format(FORMAT_DAY_MONTH_YEAR[this.$i18n.locale]);
    }
    interval = seconds / 86400;
    if (interval > (1 + timeToMidnight)) {
      if (this.$i18n.locale != 'ja') {
        return this.$moment(Number(value)).locale(this.$i18n.locale).format(FORMAT_DAY_MONTH[this.$i18n.locale]);
      }
      return (this.$moment(Number(value)).locale(this.$i18n.locale).format(FORMAT_DAY_MONTH[this.$i18n.locale]) + '日' );
    }
    if (interval > 1) {
      return this.$t('common.yesterday').toString();
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + this.$tc('time.hour_forums', Math.floor(interval)).toString();
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' ' + this.$tc('time.minute_forums', Math.floor(interval)).toString();
    }
    return this.$t('time.just_now_forums').toString();
  }
  getShowTimeFromNow(time) {
    switch (this.$i18n.locale) {
      case 'vi':
        return this.$moment(+time).fromNow(true).replace('vài giây trước','vừa xong');
      case 'en':
        return this.$moment(+time).fromNow(true).replace('a few seconds ago','just now');
      case 'ja':
        return this.$moment(+time).fromNow(true).replace('数秒前','たった今');
      default:
        return this.$moment(Number(time)).fromNow(true);
    }
  }
}
