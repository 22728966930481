import { Expose, Type } from "class-transformer";
export class PostCategory {
  @Expose({name: "id"}) id?: string;
  @Expose({name: "created_by"}) createdBy?: string;
  @Expose({name: "updated_by"}) updatedBy?: string;
  @Expose({name: "created_at"}) createdAt?: string;
  @Expose({name: "updated_at"}) updatedAt?: number;
  @Expose({name: "deleted_at"}) deletedAt?: string;
  @Expose({name: "names"}) names?: [];
  @Expose({name: "category_name"}) categoryName?: string;
  @Expose({name: "banner"}) banner?: string;
}