export default function ({ app, redirect, route, $auth, $formatSlugUrl, $fromUUID }) {
    if ($auth.loggedIn && $auth.user.property_id && !route.fullPath.includes(`${$fromUUID($auth.user.property_id)}`)) {
    //   return redirect(app.localePath({
    //     name: 'candidates-slug',
    //     params: {slug: $formatSlugUrl($auth.user.name, $fromUUID($auth.user.property_id))}
    //   }));
        if ($auth.user.support_language.code === 'ja') {
            return redirect(app.localePath('/forums'));
        }
        return redirect(app.localePath('/posts'));
    }
  }
  