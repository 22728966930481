// import "reflect-metadata";
import { Expose, Type } from "class-transformer";
import { MultilName } from './multil_name';

export class Common {
    @Expose() id?: string;
    @Expose({ name: "ja" }) ja?: string;
    @Expose({ name: "vi" }) vi?: string;
    @Expose({name: 'country_code'}) countryCode?: string;
    @Expose({name: 'code'}) code?: string;
    @Expose({name: 'name'}) name?: string;
    @Type(() => MultilName) names?: MultilName[];
    @Expose() desc?: string;
}
