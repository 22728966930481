export default function ({ app, redirect, $formatSlugUrl, $fromUUID, $auth }) {
  if (!$auth.loggedIn) {
    if (app.i18n.locale === 'ja') {
      return redirect(app.localePath('/forums'));
    }
    return;
  }
  else if ($auth.loggedIn && $auth.user.property_id && $auth.user.support_language.code === 'ja') {
    return redirect(app.localePath('/forums'));
  }
  return;
}