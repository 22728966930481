import { Expose,Type } from "class-transformer";
import { EnglishCertificate } from "./englishCertificate";

export default class EnglishLevel {
  @Expose()  level?: string;
  @Expose({name: "certificates"})
  @Type(() => EnglishCertificate) certificates?: EnglishCertificate[];
  constructor() {
    this.level = "";
    this.certificates = [];
  }
}
