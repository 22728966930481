import { Expose, Type } from "class-transformer";

export class HomeSale {
  @Expose({name: "id"}) id?: String;
  @Expose({name: "address"}) address?: String;
  @Expose({name: "created_at"}) createdAt?: Number;
  @Expose({name: "title"}) title?: String;
  @Expose({name: "building_structure"}) buildingStructure?: String;
  @Expose({name: "cover_image"}) coverImage?: String;
  @Expose({name: "price"}) price?: Number;
  @Expose({name: "category_id"}) categoryId?: String;
  @Expose({name: "floor_area"}) floorArea?: Number;
  @Expose({name: "construction_area"}) constructionArea?: Number;
  @Expose({name: "purposes_of_using"}) purposesOfUsing?: String;
  @Expose({name: "parking_lot"}) parkingLot?: String;
  @Expose({name: "urban_planning"}) urbanPlanning?: String;
  @Expose({name: "city_id"}) cityId?: String;
  @Expose({name: "is_like"}) isLike?: Boolean;
}
