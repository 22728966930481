
export default function({ store, redirect, route, app, $fromUUID }) {
  // if (
  //   (isGuard(route, "jobs/create") || isGuard(route, "companies/create")) &&
  //   !checkRole(store, "COMPANY")
  // ) {
  //   return redirect("/");
  // }
  // if (isGuard(route, "candidates/create") && !checkRole(store, "CANDIDATE")) {
  //   return redirect("/");
  // }
}

function checkRole(store, role) {
  if (!store.state.auth.user) return false;
  if (store.state.auth.user.role != role) return false;
  return true;
}
function isGuard(route, path) {
  if (route.matched.some(record => record.path.indexOf(path) > -1)) {
    return true;
  }
  return false;
}
