import { Expose } from "class-transformer";
export default class UserInfo {
  @Expose()  id?: string;
  @Expose()  role?: string;
  @Expose({ name: "property_id" })  propertyID?: string ;
  @Expose({ name: "first_name"})  firstName?: string;
  @Expose({ name: "last_name"})  lastName?: string;
  @Expose()  name?: string;
  @Expose()  email?: string;
  @Expose()  avatar?: string;
  @Expose()  active?: string;
}
